<template>
  <v-menu
    rounded="lg"
    transition="fade"
    v-model="displayMenu"
    :position-x="positionX"
    :position-y="positionY"
    absolute
    offset-y
  >
    <v-card v-if="displayMenu" rounded="lg" style="overflow: hidden" outlined>
      <v-row no-gutters>
        <v-card flat rounded="lg" class="px-3 pt-3 pb-3 flex" style="overflow: hidden">
          <v-row align="center" justify="space-between" class="pb-1 flex-nowrap" no-gutters>
            <span class="text-body-2 font-weight-regular text-uppercase text-truncate">
              <v-icon class="mr-1" :color="color" small>{{ icon }}</v-icon>
              <span>{{ title }}</span>
            </span>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-row class="" no-gutters>
                <span class="text-h5 font-weight-medium"
                  >{{ value }}<span class="text-h6 font-weight-light ml-1">{{ units }}</span></span
                >
              </v-row>
              <v-row class="" no-gutters>
                <span class="text-body-2 font-weight-regular">{{ timestamp }}</span>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
import bus from '@/core/helpers/bus'
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import HelperMixin from '@/core/mixins/HelperMixin'
import consts from '@/consts'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      items: [{ title: 'Click Me' }, { title: 'Click Me' }, { title: 'Click Me' }, { title: 'Click Me 2' }],
      displayMenu: false,
    }
  },
  components: {},
  watch: {
    showMenu(val) {
      this.displayMenu = val
    },
    displayMenu(val) {
      if (!val) {
        bus.$emit('toggleContextMenu', val)
      }
    },
  },
  methods: {
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value

      let days = Difference_In_Time / (3600000 * 24)

      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 6) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .calendar()
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return ''
    },
  },
  computed: {
    ...mapState('auth', ['appTheme', 'partner']),
    title() {
      if (consts.metricList[this.metricToDisplay.eventType].commonName) {
        return consts.metricList[this.metricToDisplay.eventType].commonName
      }
      return this.metricToDisplay.name
    },
    icon() {
      if (consts.metricList[this.metricToDisplay.eventType].icon) {
        return consts.metricList[this.metricToDisplay.eventType].icon
      }
      return 'mdi-water'
    },
    timestamp() {
      return this.relativeTime(this.metricToDisplay.timestamp)
    },
    value() {
      let innerVal = null

      let eventData = this.metricToDisplay.eventData

      switch (this.metricToDisplay.eventType) {
        case 'MEASUREMENT_BLOOD_PRESSURE':
          innerVal = eventData.systolicValue + '/' + eventData.diastolicValue
          break
        case 'MEASUREMENT_BODY_WEIGHT':
          innerVal = Math.round(eventData.value * 2.205 * 10) / 10
          break
        case 'MEASUREMENT_BLOOD_GLUCOSE':
          innerVal = Math.round(eventData.value * 100) / 100
          break
        case 'MEASUREMENT_BODY_TEMPERATURE':
          innerVal = Math.round(((eventData.value * (9 / 5) + 32) * 100) / 100)
          break
        case 'MEASUREMENT_PULSE':
          innerVal = Math.round(eventData.value)
          break
        case 'MEASUREMENT_ECG':
          if (eventData.afib) {
            innerVal = 'Possible Afib'
          } else {
            innerVal = 'No Afib'
          }
          break
        default:
          innerVal = eventData.value
          break
      }

      return innerVal
    },
    units() {
      if (consts.metricList[this.metricToDisplay.eventType].units) {
        return consts.metricList[this.metricToDisplay.eventType].units
      }
      return this.metricToDisplay.units
    },
  },
  props: {
    positionX: {
      default: 0,
    },
    positionY: {
      default: 0,
    },
    showMenu: {
      default: false,
    },
    metricToDisplay: {
      type: Object,
      default: () => {
        return {}
      },
    },
    color: null,
  },
}
</script>

<style></style>
