<template>
  <v-container class="px-0 pt-0" fluid>
    <patient-meta-combined-charts class="mb-10" />
    <v-row no-gutters>
      <v-card flat color="transparent" class="flex">
        <chart-notes />
      </v-card>

      <v-navigation-drawer color="transparent" permanent floating app clipped right>
        <portal-target class="mb-6" name="patientFeedFirstRightPanel" slim></portal-target>
        <portal-target name="patientFeedSecondRightPanel" slim></portal-target>
      </v-navigation-drawer>
    </v-row>
  </v-container>
</template>

<script>
import ChartNotes from '../components/ChartNotes.vue'
import PatientMetaCombinedCharts from '../components/metriccharts/PatientCombinedCharts.vue'
import { mapState, mapActions } from 'vuex'
import bus from '@/core/helpers/bus'

export default {
  components: { ChartNotes, PatientMetaCombinedCharts },
  computed: {
    ...mapState('patient', ['patient']),
    ...mapState('notes', ['loadingPatientNotes', 'patientNotes']),
  },
  watch: {
    loadingPatientNotes(val) {
      if (val) {
        this.showNotesLoading = true
      } else {
        this.showNotesLoading = false
      }
    },
  },
  data() {
    return {
      doneLoading: false,
      showNotesLoading: true,
    }
  },
  methods: {
    ...mapActions('patient', ['getPatient']),
    createNewNote() {
      bus.$emit('addNote')
    },
  },

  mounted() {
    if (this.$route.meta.openAlertNote) {
      window.history.replaceState(null, 'NOVO RPM', '/patients/' + this.$route.params.id + '/feed')
      setTimeout(
        () =>
          bus.$emit('addNoteToPoint', {
            type: 'metric',
            data: this.patient.thresholdBreachAlertNotifications[0].event,
          }),
        400
      )
    }

    if (this.$route.query.open === 'message') {
      window.history.replaceState(null, 'NOVO RPM', '/patients/' + this.$route.params.id + '/feed')
      setTimeout(() => bus.$emit('messenger:open', { patient: this.patient }), 800)
    }

    if (this.$route.query.open === 'note') {
      window.history.replaceState(null, 'NOVO RPM', '/patients/' + this.$route.params.id + '/feed')
      setTimeout(() => bus.$emit('addNote'), 800)
    }

    if (this.$route.query.open === 'video') {
      window.history.replaceState(null, 'NOVO RPM', '/patients/' + this.$route.params.id + '/feed')
      setTimeout(() => bus.$emit('video:open', this.patient), 800)
    }
  },
}
</script>

<style></style>
