<template>
  <v-container v-if="patient" class="pa-0" fluid>
    <patient-combined-charts-context-menu
      :showMenu="hoverPoint.show"
      :positionX="hoverPoint.x"
      :positionY="hoverPoint.y"
      :metricToDisplay="hoverPoint.payload"
      :color="hoverPoint.color"
    />
    <v-row justify="start" dense>
      <v-col
        v-for="(item, index) in individualMetrics"
        :key="index"
        cols="12"
        :md="Math.floor(12 / individualMetrics.length) < 4 ? 3 : Math.floor(12 / individualMetrics.length)"
        :lg="Math.floor(12 / individualMetrics.length) < 4 ? 3 : Math.floor(12 / individualMetrics.length)"
        :xl="Math.floor(12 / individualMetrics.length) < 4 ? 3 : Math.floor(12 / individualMetrics.length)"
      >
        <patient-combined-charts-thumbnail
          @toggle="toggleSeries(item.latestEvent.eventType)"
          :metricToDisplay="item.latestEvent"
          :color="colorSet[item.latestEvent.eventType].card"
        ></patient-combined-charts-thumbnail>
      </v-col>
    </v-row>
    <v-card rounded="lg" class="mt-4 pa-4" outlined>
      <v-row align="center" no-gutters>
        <v-btn depressed small class="mr-1" @click="snapTo365()">1 Yr</v-btn>
        <v-btn depressed small class="mr-1" @click="snapTo90()">90 Day</v-btn>
        <v-btn depressed small class="mr-1" @click="snapTo30()">30 Day</v-btn>
        <v-btn depressed small class="mr-1" @click="snapTo7()">7 Day</v-btn>
        <v-btn v-show="showResetZoom && false" depressed small class="mr-1" @click="resetZoom()">Reset Zoom</v-btn>
        <portal-target name="chartInstructions" slim />

        <v-spacer />
        <span class="text-h6 font-weight-bold">
          {{ labelForRange }}
        </span>
      </v-row>

      <v-hover v-slot="{ hover }">
        <v-card rounded="lg" flat>
          <v-fab-transition>
            <v-btn
              v-show="showBack && (hover || isMobileAppleDevice)"
              fab
              dark
              small
              style="position: absolute; left: -18px; top: 130px; z-index:10"
              color="primary"
              @click="scootBack()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-fab-transition>

          <v-fab-transition>
            <v-btn
              v-show="showForward && (hover || isMobileAppleDevice)"
              fab
              dark
              small
              style="position: absolute; right: -18px; top: 130px; z-index:10"
              color="primary"
              @click="scootForward()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-fab-transition>
          <portal to="chartInstructions">
            <v-fade-transition origin="start">
              <span class="ml-2" v-show="hover">
                <v-icon color="grey darken-1" class="mx-1">mdi-shape-rectangle-plus</v-icon
                ><span class="text-body-2 font-weight-regular text--secondary">Drag to select range</span>
              </span>
            </v-fade-transition>
          </portal>
          <vue-apex-charts
            v-if="allMetricsDone"
            class="mainChart"
            v-bind:class="hoverPoint.show ? 'pointerClass' : 'zoomClass'"
            ref="chartObject"
            :options="chartOptions"
            height="300"
            type="line"
            :series="twoDimensionalMetrics"
          ></vue-apex-charts>

          <!-- <vue-apex-charts
            style="margin-top: -28px"
            v-if="allMetricsDone"
            class="mainChart"
            ref="chartObject2"
            :options="chartOptionsGary"
            height="50"
            type="line"
            :series="oneDimensionalMetrics"
          ></vue-apex-charts> -->

          <v-card flat height="200" v-if="!allMetricsDone">
            <v-row no-gutters class="fill-height" align="center" justify="center">
              <v-progress-circular color="primary" indeterminate />
            </v-row>
          </v-card>
        </v-card>
      </v-hover>
      <v-row no-gutters>
        <v-expand-transition>
          <v-card flat rounded="lg" class="flex pt-0">
            <v-divider />
            <v-simple-table height="230" fixed-header dense style="width: 100%; background-color: transparent;">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Time</th>
                    <th v-for="(metric, index) in visibleMetrics" :key="index" class="text-center">
                      {{ metric.abbreviation }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-bind:class="
                      hoverPoint.show && hoverPoint.timestamp === parseInt(item.timestamp) ? 'hoveredValue' : null
                    "
                    v-for="(item, index) in dataListForVisible"
                    :key="index"
                  >
                    <td style="max-width: 220px; width: 220px; text-align: left">
                      {{ relativeTime(parseInt(item.timestamp)) }}
                    </td>
                    <td v-for="(metric, index) in visibleMetrics" :key="index" class="text-center">
                      <patient-combined-charts-event-value
                        v-if="item.data[metric.eventType]"
                        :metricToDisplay="item.data[metric.eventType]"
                      />
                      <v-btn
                        @click="showPDF(item.data[metric.eventType])"
                        small
                        v-show="metric.eventType === 'MEASUREMENT_ECG' && item.data[metric.eventType]"
                        class="ml-1"
                        color="primary"
                        icon
                        ><v-icon>mdi-pdf-box</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-expand-transition>
      </v-row>
    </v-card>
    <portal to="patientFeedFirstRightPanel">
      <v-card outlined class="rightBar pt-2 pr-4" color="transparent">
        <v-row v-show="false" no-gutters>
          <span class="text-body-2 font-weight-medium text--secondary">
            Patient Activity
          </span>
        </v-row>
        <patient-activity-calendar />
        <v-row no-gutters>
          <span class="text-body-2 font-weight-medium text--secondary">
            {{ getMonthAndYearFromTimestamp(new Date().getTime()) }} Chart Time
          </span>
        </v-row>
        <v-row align="center" no-gutters class="px-0 pt-3">
          <span class="text-body-2 ">{{ msToTime(patient.millisecondsThisPeriod) }} </span>
        </v-row>
        <v-row class="pt-6" no-gutters>
          <span class="text-body-2 font-weight-medium text--secondary">
            Current RPM Period
          </span>
        </v-row>
        <v-row align="center" no-gutters class="px-0 pt-3">
          <span class="text-body-2 mr-4"> {{ currentPeriodStart }} </span><v-divider /><span class="text-body-2 ml-4">
            {{ currentPeriodEnd }}
          </span>
        </v-row>

        <v-row align="center" justify="space-between" no-gutters class=" pt-6">
          <span class="text-body-2 font-weight-medium text--secondary">
            Statistics
          </span>
          <v-btn
            depressed
            v-bind:color="thirtyStats ? 'primary' : 'white'"
            @click=";(thirtyStats = true), (sixtyStats = false), (ninetyStats = false)"
            x-small
            >30</v-btn
          >
          <v-btn
            @click=";(sixtyStats = true), (thirtyStats = false), (ninetyStats = false)"
            x-small
            depressed
            v-bind:color="sixtyStats ? 'primary' : 'white'"
            >60</v-btn
          >
          <v-btn
            @click=";(ninetyStats = true), (sixtyStats = false), (thirtyStats = false)"
            x-small
            depressed
            v-bind:color="ninetyStats ? 'primary' : 'white'"
            >90</v-btn
          >
        </v-row>

        <v-row v-for="(item, index) in statistics" :key="index" no-gutters class="pt-3">
          <v-card v-show="item.name !== 'ECG'" flat class="flex text-body-2" color="transparent" no-gutters>
            <v-row class="font-weight-bold" no-gutters>
              {{ item.name }}
            </v-row>
            <v-row v-show="thirtyStats" justify="space-between" no-gutters
              ><span>30 day high</span>{{ item.thirty.high }}
            </v-row>
            <v-row v-show="thirtyStats" justify="space-between" no-gutters
              ><span>30 day low</span>{{ item.thirty.low }}
            </v-row>
            <v-row v-show="thirtyStats" justify="space-between" no-gutters
              ><span>30 day avg</span>{{ item.thirty.avg }}
            </v-row>
            <v-row v-show="sixtyStats" justify="space-between" no-gutters
              ><span>60 day high</span>{{ item.sixty.high }}
            </v-row>
            <v-row v-show="sixtyStats" justify="space-between" no-gutters
              ><span>60 day low</span>{{ item.sixty.low }}
            </v-row>
            <v-row v-show="sixtyStats" justify="space-between" no-gutters
              ><span>60 day avg</span>{{ item.sixty.avg }}
            </v-row>
            <v-row v-show="ninetyStats" justify="space-between" no-gutters
              ><span>90 day high</span>{{ item.ninety.high }}
            </v-row>
            <v-row v-show="ninetyStats" justify="space-between" no-gutters
              ><span>90 day low</span>{{ item.ninety.low }}
            </v-row>
            <v-row v-show="ninetyStats" justify="space-between" no-gutters
              ><span>90 day avg</span>{{ item.ninety.avg }}
            </v-row>
          </v-card>
        </v-row>
      </v-card>
    </portal>

    <v-row justify="center">
      <v-dialog v-model="ecgDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card flat color="green">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="ecgDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>ECG</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="ecgDialog = false">
                Save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <iframe
            frameborder="0"
            style="overflow:hidden;height:100%;width:100%"
            height="500px"
            width="100%"
            :src="dialogURL"
          ></iframe>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { PDFDocument } from 'pdf-lib'

import HelperMixin from '@/core/mixins/HelperMixin'
import VueApexCharts from 'vue-apexcharts'
import PatientCombinedChartsThumbnail from '../metriccharts/PatientCombinedChartsThumbnail.vue'
import PatientActivityCalendar from '../PatientActivityCalendar.vue'
import dayjs from 'dayjs'
import PatientCombinedChartsContextMenu from '../metriccharts/PatientCombinedChartsContextMenu.vue'
import PatientCombinedChartsEventValue from '../metriccharts/PatientCombinedChartsEventValue.vue'
import bus from '@/core/helpers/bus'
import moment from 'moment-timezone'
import consts from '@/consts'

// import bus from '@/core/helpers/bus'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      thirtyStats: true,
      sixtyStats: false,
      ninetyStats: false,
      hoverPoint: { show: false, point: null, x: null, y: null },
      chartRange: 7,
      chartStartOffset: 1,
      expectedMetricList: [],
      expandList: false,
      labelForMaxY: '100',
      labelForRange: 'Jul 30 - Aug 6',
      chartXMin: 0,
      chartXMax: 0,
      ecgDialog: false,
      dialogURL:
        'https://storage.googleapis.com/nv_public/ecg/da736bf6d6ebe355b15c3eb88c08301c8576c36775cb167a5289aa09a9beabcf.raw',
      tab: null,
      items: ['30', '60', '90'],
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
  components: {
    VueApexCharts,
    PatientCombinedChartsThumbnail,
    PatientActivityCalendar,
    PatientCombinedChartsContextMenu,
    PatientCombinedChartsEventValue,
  },
  watch: {},
  computed: {
    ...mapState('auth', ['configuration', 'appTheme']),

    ...mapState('patient', ['patient']),
    ...mapState('newmetrics', ['individualMetrics', 'combinedSeries']),
    twoDimensionalMetrics() {
      return this.combinedSeries
    },
    oneDimensionalMetrics() {
      return this.combinedSeries.filter(function(e) {
        return e.name === 'MEASUREMENT_ECG'
      })
    },
    visibleMetrics() {
      let onlyTheVisibleOnes = this.individualMetrics.filter(function(e) {
        return !e.hidden
      })

      return onlyTheVisibleOnes.map(x => {
        return {
          eventType: x.eventType,
          abbreviation: consts.metricList[x.eventType].abbreviation,
          units: consts.metricList[x.eventType].units,
        }
      })
    },
    combinedMetricDataList() {
      // should only show the ones that are visible?
      let onlyTheVisibleOnes = this.individualMetrics.filter(function(e) {
        return !e.hidden
      })

      let arrayOfValues = []

      let timestampDict = {}

      onlyTheVisibleOnes.forEach(metric => {
        metric.series[0].data.forEach(event => {
          if (timestampDict[event.fullEvent.timestamp]) {
            if (event.fullEvent.eventType)
              timestampDict[event.fullEvent.timestamp][event.fullEvent.eventType] = event.fullEvent
          } else {
            timestampDict[event.fullEvent.timestamp] = {}
            timestampDict[event.fullEvent.timestamp][event.fullEvent.eventType] = event.fullEvent
          }
        })
      })
      let stamps = Object.keys(timestampDict).sort(function(a, b) {
        return b - a
      })
      stamps.forEach(timestamp => {
        arrayOfValues.push({ timestamp: timestamp, data: timestampDict[timestamp], eventIDArray: [] })
      })

      // only return the ones between the visible start and end
      return arrayOfValues
    },
    dataListForVisible() {
      let vm = this
      return this.combinedMetricDataList.filter(function(e) {
        return e.timestamp >= vm.chartXMin && e.timestamp <= vm.chartXMax
      })
    },
    allMetricsDone() {
      if (this.individualMetrics.length === this.expectedMetricList.length) {
        return true
      }

      return false
    },
    isMobileAppleDevice() {
      if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
        return true
      }
      return false
    },
    showForward() {
      return this.chartXMax < new Date().getTime()
    },
    showBack() {
      if (this.chartStartOffset * this.chartRange < 360) {
        return true
      }
      return false
    },
    showResetZoom() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 7)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      let normalRange = chartEnd.getTime() - chartStart.getTime()

      let currentRange = this.chartXMax - this.chartXMin

      return currentRange < normalRange
    },
    currentPeriodStart() {
      if (this.patient.rpm.enrollment.firstData) {
        // this tells me when the next 99454 is up

        let periodStart = new Date(this.patient.rpm.enrollment.firstData)
        periodStart.setHours(0, 0, 0, 0)

        let rightNow = new Date()

        let monitoringPeriods = []

        while (periodStart.getTime() < rightNow.getTime()) {
          // this will add thirty days to the period start. when this ends, period start will be the start of NEXT period.
          let start = periodStart.getTime()
          periodStart.setDate(periodStart.getDate() + 30)
          let end = new Date(periodStart.getTime() - 1).getTime()
          monitoringPeriods.push({ start: start, end: end })
        }

        let startDateObject = new Date(monitoringPeriods[monitoringPeriods.length - 1].start)

        return dayjs(startDateObject.getTime()).format('MMM DD')

        // what if periods were 30 days from the start of the month?
        // what about feb?
      }

      return 'Not Enrolled'
    },
    currentPeriodEnd() {
      if (this.patient.rpm.enrollment.firstData) {
        // this tells me when the next 99454 is up

        let periodStart = new Date(this.patient.rpm.enrollment.firstData)
        periodStart.setHours(0, 0, 0, 0)

        let rightNow = new Date()

        let monitoringPeriods = []

        while (periodStart.getTime() < rightNow.getTime()) {
          // this will add thirty days to the period start. when this ends, period start will be the start of NEXT period.
          let start = periodStart.getTime()
          periodStart.setDate(periodStart.getDate() + 30)
          let end = new Date(periodStart.getTime() - 1).getTime()
          monitoringPeriods.push({ start: start, end: end })
        }

        let endDateObject = new Date(monitoringPeriods[monitoringPeriods.length - 1].end)

        return dayjs(endDateObject.getTime()).format('MMM DD')

        // what if periods were 30 days from the start of the month?
        // what about feb?
      }
      return 'not enrolled'
    },
    statistics2() {
      let thirtyDays = new Date()
      thirtyDays.setHours(0, 0, 0, 0)
      thirtyDays.setDate(thirtyDays.getDate() - 31)

      let sixtyDays = new Date()
      sixtyDays.setHours(0, 0, 0, 0)
      sixtyDays.setDate(sixtyDays.getDate() - 61)

      let ninetyDays = new Date()
      ninetyDays.setHours(0, 0, 0, 0)
      ninetyDays.setDate(ninetyDays.getDate() - 91)

      let statsToReturn = []
      this.individualMetrics.forEach(metric => {
        let item = {}
        item.high = '-'
        item.avg = '-'
        if (consts.metricList[metric.latestEvent.eventType].commonName) {
          item.name = consts.metricList[metric.latestEvent.eventType].commonName
        }
        if (metric.series[0].data.length > 0 && metric.series[0].data[0].x > thirtyDays.getTime()) {
          let seriesForAverage = metric.series[0].data.filter(function(e) {
            return e.x > thirtyDays.getTime()
          })

          let high = Math.max.apply(
            Math,
            seriesForAverage.map(function(o) {
              return o.y
            })
          )

          let indexOfHigh = seriesForAverage
            .map(function(o) {
              return o.y
            })
            .indexOf(high)

          item.high = high

          const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length

          item.avg = Math.ceil(arrAvg(seriesForAverage.map(x => x.y)))

          if (metric.latestEvent.eventType === 'MEASUREMENT_BLOOD_PRESSURE') {
            let secondSeriesForAverage = metric.series[1].data.filter(function(e) {
              return e.x > thirtyDays.getTime()
            })
            let diastolic = Math.ceil(arrAvg(secondSeriesForAverage.map(x => x.y)))

            item.avg = item.avg + '/' + diastolic

            item.high = item.high + '/' + secondSeriesForAverage[indexOfHigh].y
          }
        }
        // get the high?
        statsToReturn.push(item)
      })
      return statsToReturn
    },
    statistics() {
      let thirtyDays = new Date()
      thirtyDays.setHours(0, 0, 0, 0)
      thirtyDays.setDate(thirtyDays.getDate() - 31)

      let sixtyDays = new Date()
      sixtyDays.setHours(0, 0, 0, 0)
      sixtyDays.setDate(sixtyDays.getDate() - 61)

      let ninetyDays = new Date()
      ninetyDays.setHours(0, 0, 0, 0)
      ninetyDays.setDate(ninetyDays.getDate() - 91)

      let ranges = [
        { date: thirtyDays, name: 'thirty' },
        { date: sixtyDays, name: 'sixty' },
        { date: ninetyDays, name: 'ninety' },
      ]

      let statsToReturn = []
      this.individualMetrics.forEach(metric => {
        let item = {}
        if (consts.metricList[metric.latestEvent.eventType].commonName) {
          item.name = consts.metricList[metric.latestEvent.eventType].commonName
        }

        ranges.forEach(range => {
          item[range.name] = {}
          let subItem = item[range.name]

          subItem.name = range.name
          subItem.high = '-'
          subItem.low = '-'
          subItem.avg = '-'

          if (metric.series[0].data.length > 0 && metric.series[0].data[0].x > range.date.getTime()) {
            let seriesForAverage = metric.series[0].data.filter(function(e) {
              return e.x > range.date.getTime()
            })

            let high = Math.max.apply(
              Math,
              seriesForAverage.map(function(o) {
                return o.y
              })
            )

            let low = Math.min.apply(
              Math,
              seriesForAverage.map(function(o) {
                return o.y
              })
            )

            let indexOfHigh = seriesForAverage
              .map(function(o) {
                return o.y
              })
              .indexOf(high)

            let indexOfLow = seriesForAverage
              .map(function(o) {
                return o.y
              })
              .indexOf(low)

            subItem.high = high
            subItem.low = low

            const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length

            subItem.avg = Math.ceil(arrAvg(seriesForAverage.map(x => x.y)))

            if (metric.latestEvent.eventType === 'MEASUREMENT_BLOOD_PRESSURE') {
              let secondSeriesForAverage = metric.series[1].data.filter(function(e) {
                return e.x > range.date.getTime()
              })
              let diastolic = Math.ceil(arrAvg(secondSeriesForAverage.map(x => x.y)))

              subItem.avg = subItem.avg + '/' + diastolic

              subItem.high = subItem.high + '/' + secondSeriesForAverage[indexOfHigh].y
              subItem.low = subItem.low + '/' + secondSeriesForAverage[indexOfLow].y
            }
          }
        })

        // get the high?
        statsToReturn.push(item)
      })
      return statsToReturn
    },
    maxForCombinedChart() {
      let yMax = 0

      this.combinedSeries.forEach(graphSeries => {
        let localMax = Math.max.apply(
          Math,
          graphSeries.data.map(function(o) {
            return o.y
          })
        )

        if (localMax > yMax) {
          yMax = localMax
        }
      })
      return yMax
    },
    colorSet() {
      let colorSet = {}
      colorSet.MEASUREMENT_BLOOD_PRESSURE = {
        card: '#27A0FC',
        stroke: '#ffffff',
        dark: true,
        icon: 'mdi-heart',
      }

      colorSet.MEASUREMENT_BLOOD_OXYGEN = {
        card: '#27A0FC',
        stroke: '#ffffff',
        dark: true,
        icon: 'mdi-heart',
      }

      colorSet.MEASUREMENT_BODY_WEIGHT = {
        card: '#375094',
        stroke: '#ffffff',
        dark: true,
        icon: 'mdi-scale-bathroom',
      }

      colorSet.MEASUREMENT_BLOOD_GLUCOSE = {
        card: '#00C0AC',
        stroke: '#ffffff',
        dark: true,
        icon: 'mdi-water',
      }

      colorSet.MEASUREMENT_PULSE = {
        card: '#EE1C24',
        stroke: '#ffffff',
        dark: true,
        icon: 'mdi-heart-pulse',
      }

      colorSet.MEASUREMENT_ECG = {
        card: '#000000',
        stroke: '#ffffff',
        dark: true,
        icon: 'mdi-heart-pulse',
      }

      return colorSet
    },
    chartOptions() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 7)

      let chartEndDate = new Date()
      chartEndDate.setHours(23, 59, 59, 999)
      chartEndDate.setDate(chartEndDate.getDate())

      let chartStartTimestamp = chartStart.getTime()
      let chartEndTimestamp = chartEndDate.getTime()

      //     let keyColor = colorSet[props.metricToDisplay.eventType]

      // color array

      let discreetMarkers = []
      let seriesIndex = 0
      this.combinedSeries.forEach(series => {
        let dataIndex = 0

        series.data.forEach(element => {
          if (element.alert) {
            let marker = {
              seriesIndex: seriesIndex,
              dataPointIndex: dataIndex,

              fillColor: '#FFED3A',
              strokeColor: '#333333',
              size: 4,
              strokeWidth: 2,
              hover: {
                sizeOffset: 4,
              },
            }

            discreetMarkers.push(marker)
          }
          dataIndex = dataIndex + 1
        })
        seriesIndex = seriesIndex + 1
      })

      let colorArray = []

      this.twoDimensionalMetrics.forEach(series => {
        colorArray.push(this.colorSet[series.latestEvent.eventType].card)
      })
      let vm = this
      return {
        chart: {
          zoom: {
            enabled: true,
            type: 'x',
          },
          toolbar: {
            show: false,
          },

          id: toString(new Date().getTime()),

          sparkline: { enabled: false },
          animations: { enabled: false },
          events: {
            // mouseMove: function(event, chartContext, config) {
            //   console.log(config)
            //   //vm.hoverPoint = config.dataPointIndex
            // },
            // mouseLeave: function() {
            //   // ...
            //   console.log('mouse left!!!')
            // },
            // mouseMove: function(event, chartContext, config) {
            //   // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts.
            //   console.log('mouse moved')
            //   console.log(event)
            //   console.log(chartContext)
            //   console.log(config)
            // },
            mounted: function(chartContext, config) {
              vm.chartXMin = config.globals.minX
              vm.chartXMax = config.globals.maxX

              let startText = moment(config.globals.minX)
                .tz(moment.tz.guess())
                .format('MMM DD')

              let endText = moment(config.globals.maxX)
                .tz(moment.tz.guess())
                .format('MMM DD')

              let momentOfToday = moment(new Date().getTime())
                .tz(moment.tz.guess())
                .format('MMM DD')

              if (momentOfToday === startText) {
                // the chart starts on today.
                startText = 'Today'
                endText = ''
              }

              if (momentOfToday === endText) {
                // the chart ends on today.

                endText = 'Today'
              }

              if (startText !== '' && endText !== '') {
                startText = startText + ' - '
              }

              if (startText === endText) {
                startText = ''
              }
              vm.labelForRange = startText + endText
              vm.labelForMaxY = config.globals.maxY
            },
            zoomed: function(chartContext, { xaxis }) {
              vm.chartXMin = xaxis.min
              vm.chartXMax = xaxis.max
              let startText = moment(xaxis.min)
                .tz(moment.tz.guess())
                .format('MMM DD')

              let endText = moment(xaxis.max)
                .tz(moment.tz.guess())
                .format('MMM DD')

              let momentOfToday = moment(new Date().getTime())
                .tz(moment.tz.guess())
                .format('MMM DD')

              if (momentOfToday === startText) {
                // the chart starts on today.
                startText = 'Today'
                endText = ''
              }

              if (momentOfToday === endText) {
                // the chart ends on today.

                endText = 'Today'
              }

              if (startText === endText) {
                startText = ''
              }

              if (startText !== '' && endText !== '') {
                startText = startText + ' - '
              }

              vm.labelForRange = startText + endText
            },
            dataPointSelection: (event, chartContext, config) =>
              this.selectedPointTwoDimensional({ event, chartContext, config }),

            dataPointMouseEnter: function(event, chartContext, config) {
              vm.hoverPoint = {
                show: true,
                point: {
                  series: config.seriesIndex,
                  value: config.dataPointIndex,
                },
                payload: vm.twoDimensionalMetrics[config.seriesIndex].data[config.dataPointIndex].fullEvent,
                color:
                  vm.colorSet[
                    vm.twoDimensionalMetrics[config.seriesIndex].data[config.dataPointIndex].fullEvent.eventType
                  ].card,
                x: event.x + 15,
                y: event.y + 15,
                timestamp: vm.twoDimensionalMetrics[config.seriesIndex].data[config.dataPointIndex].fullEvent.timestamp,
              }
            },
            dataPointMouseLeave: function() {
              vm.hoverPoint = {
                show: false,
                point: null,
                payload: null,
                x: null,
                y: null,
              }
            },
            // markerClick: function(
            //   event,
            //   chartContext,
            //   { seriesIndex, dataPointIndex, config }
            // ) {
            //   console.log(event)
            //   console.log(seriesIndex)
            //   console.log(dataPointIndex)
            //   console.log(config)
            // },
          },
        },
        legend: {
          show: false,
        },
        colors: colorArray,
        stroke: { curve: 'smooth', colors: colorArray, width: 2 },
        fill: { opacity: 1, type: 'solid' },
        tooltip: {
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            return vm.returnCustomTooltip({
              series,
              seriesIndex,
              dataPointIndex,
              w,
            })
          },
          marker: {
            show: false,
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
          intersect: true,
          shared: false,
          enabled: true,
          x: {
            show: true,
          },
          y: {
            show: false,
          },
        },
        xaxis: {
          floating: false,
          type: 'datetime',
          max: chartEndTimestamp,
          min: chartStartTimestamp,
          crosshairs: {
            show: true,
            with: 20,
          },
          forceNiceScale: true,
          labels: {
            datetimeUTC: false,
            show: true,
            rotate: 0,
            maxHeight: 30,
            // formatter: function(timestamp) {
            //   // is this the first one of the day/month/year?

            //   return moment(timestamp)
            //     .tz(moment.tz.guess())
            //     .format('h:mm a')
            // },
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM d',
              day: 'MMM d',
              hour: 'h:mm tt',
              minute: 'h:mm tt',
            },
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Source Sans Pro, Helvetica, Arial, sans-serif',
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            show: true,
            align: 'left',
            minWidth: 40,
            maxWidth: 160,
            style: {
              colors: ['#757575'],
              fontSize: '15px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: -15,
            offsetY: 3,
            rotate: 0,
            formatter: function(val, index) {
              if (index === 0) {
                return ''
              }

              if (val > vm.maxForCombinedChart + vm.maxForCombinedChart * 0.2) {
                return val.toFixed(0)
              }
              return ''
            },
          },
          min: 0,
          max: this.maxForCombinedChart + this.maxForCombinedChart * 0.2,
        },
        markers: {
          size: 4,
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeColors: '#ffffff',
          hover: {
            sizeOffset: 4,
          },
          discrete: discreetMarkers,
        },
        grid: {
          padding: { left: -40, right: 0 },
          show: true,
          position: 'back',
          row: {
            colors: ['#ffffff', '#ffffff'],
            opacity: 0.1,
          },
          column: {
            colors: ['#4485ed', '#ffffff'],
            opacity: 0.1,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    mobileClass() {
      if (!this.isMobile) {
        return 'mx-0'
      }
      return null
    },
  },
  methods: {
    msToTime(s) {
      if (!s) {
        return '0h 0m 0s'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor(s / (1000 * 60 * 60))

      hours = hours < 10 ? '' + hours : hours
      minutes = minutes < 10 ? '' + minutes : minutes
      seconds = seconds < 10 ? '' + seconds : seconds

      return hours + 'h ' + minutes + 'm ' + seconds + 's'
    },
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value

      let days = Difference_In_Time / (3600000 * 24)

      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 1) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .calendar()
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return ''
    },
    selectedPointTwoDimensional(payload) {
      let metricData = this.twoDimensionalMetrics[payload.config.seriesIndex].data[payload.config.dataPointIndex]
        .fullEvent
      // the metric needs to be processed?

      switch (metricData.eventType) {
        case 'MEASUREMENT_BLOOD_PRESSURE':
          metricData.displayValue = metricData.eventData.systolicValue + '/' + metricData.eventData.diastolicValue
          break
        case 'MEASUREMENT_BODY_WEIGHT':
          metricData.displayValue = Math.round(metricData.eventData.value * 2.205 * 10) / 10
          break
        case 'MEASUREMENT_BLOOD_GLUCOSE':
          metricData.displayValue = Math.round(metricData.eventData.value * 100) / 100
          break
        case 'MEASUREMENT_BODY_TEMPERATURE':
          metricData.displayValue = Math.round(((metricData.eventData.value * (9 / 5) + 32) * 100) / 100)
          break
        case 'MEASUREMENT_PULSE':
          metricData.displayValue = Math.round(metricData.eventData.value)
          break
        case 'MEASUREMENT_ECG':
          if (metricData.eventData.afib) {
            metricData.displayValue = 'Possible Afib'
          } else {
            metricData.displayValue = 'No Afib'
          }
          break
        default:
          metricData.displayValue = metricData.eventData.value
          break
      }

      bus.$emit('addNoteToPoint', {
        type: 'metric',
        data: metricData,
      })
    },
    getPdfUrl(byte) {
      var blob = new Blob([byte], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      return url
    },
    async showPDF(metricData) {
      try {
        // fetch the pdf from the server
        let response = await fetch(metricData.eventData.pdfUrl)
        const pdf = await response.arrayBuffer()
        // load the pdf
        const pdfDoc = await PDFDocument.load(pdf)
        // add the patient for each page
        const pages = pdfDoc.getPages()
        const fontSize = 16
        const patientText = `${this.patient?.lastName.toUpperCase() || ''} ID:${this.patient?.org?.patientId ||
          'not found'}`
        pages.forEach(page => {
          const { width, height } = page.getSize()
          page.drawText(patientText, {
            x: width - patientText.length * (fontSize / 2) - 30,
            y: height - fontSize * 1.5,
            size: fontSize,
          })
        })
        const pdfBytes = await pdfDoc.save()
        const pdfUrl = this.getPdfUrl(pdfBytes)
        // open the new pdf with patient info
        window.open(pdfUrl, 'ECG', 'width=500,height=960, toolbar=no,scrollbars=no,resizable=no,top=100,left=300')
        //this.ecgDialog = true
      } catch (e) {
        // just open the provided url if something fails adding the patient info
        window.open(
          metricData.eventData.pdfUrl,
          'ECG',
          'width=500,height=960, toolbar=no,scrollbars=no,resizable=no,top=100,left=300'
        )
        console.log(e)
      }
    },
    returnCustomTooltip({ series, seriesIndex, dataPointIndex }) {
      return (
        '<div style="display:none" class="arrow_box">' +
        '<span>' +
        series[seriesIndex][dataPointIndex] +
        '</span>' +
        '</div>'
      )
    },
    snapTo30() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 30)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      this.$refs.chartObject.zoomX(chartStart.getTime(), chartEnd.getTime())
    },
    snapTo90() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 90)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      this.$refs.chartObject.zoomX(chartStart.getTime(), chartEnd.getTime())
    },
    snapTo365() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 365)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      this.$refs.chartObject.zoomX(chartStart.getTime(), chartEnd.getTime())
    },
    snapTo180() {
      this.chartRange = 180
      this.chartStartOffset = 0
    },
    snapTo7() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 7)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      this.$refs.chartObject.zoomX(chartStart.getTime(), chartEnd.getTime())
    },

    snapTo1() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 0)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      //let length = chartEnd.getTime() - chartStart.getTime()

      let offset = 0

      this.$refs.chartObject.zoomX(chartStart.getTime() - offset, chartEnd.getTime() + offset)
    },
    resetZoom() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 7)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      let normalRange = chartEnd.getTime() - chartStart.getTime()

      let currentRange = this.chartXMax - this.chartXMin

      let rangeDifference = normalRange - currentRange

      let rangeDifferenceOffset = rangeDifference / 2

      if (this.chartXMax + rangeDifferenceOffset > chartEnd.getTime()) {
        this.snapTo7()
      } else {
        this.$refs.chartObject.zoomX(this.chartXMin - rangeDifferenceOffset, this.chartXMax + rangeDifferenceOffset)
      }
    },
    scootBack() {
      let range = this.chartXMax - this.chartXMin

      let start = this.chartXMin - range

      let end = start + range

      this.$refs.chartObject.zoomX(start, end)
    },
    scootForward() {
      let range = this.chartXMax - this.chartXMin

      let start = this.chartXMin + range

      let end = start + range

      this.$refs.chartObject.zoomX(start, end)
    },
    toggleSeries(seriesName) {
      let correspondingMetric = this.individualMetrics.find(metric => metric.eventType === seriesName)

      correspondingMetric.hidden = !correspondingMetric.hidden

      if (seriesName === 'MEASUREMENT_BLOOD_PRESSURE') {
        this.$refs.chartObject.toggleSeries(seriesName + '_SYSTOLIC')
        this.$refs.chartObject.toggleSeries(seriesName + '_DIASTOLIC')
        // this.$refs.chartObjectBackground.toggleSeries(seriesName + '_SYSTOLIC')
        // this.$refs.chartObjectBackground.toggleSeries(seriesName + '_DIASTOLIC')
      } else {
        this.$refs.chartObject.toggleSeries(seriesName)

        // this.$refs.chartObjectBackground.toggleSeries(seriesName)
      }
    },
    ...mapActions('newmetrics', ['getEventsForRange', 'getEvent', 'prepareEventData']),
    ...mapActions('patient', ['generateFacesheet']),
    ...mapMutations('newmetrics', ['clearPatientMetrics']),
  },
  props: {
    minimalist: {
      type: Boolean,
      default: false,
    },
    allowSidebar: {
      type: Boolean,
      default: true,
    },
  },

  created() {},
  beforeMount() {
    this.clearPatientMetrics()
  },
  mounted() {
    this.expectedMetricList = Object.keys(this.patient.latestEvents).filter(function(eventType) {
      return (
        eventType !== 'RPM_NOTE' &&
        eventType !== 'MEASUREMENT_BODY_TEMPERATURE' &&
        eventType !== 'MEASUREMENT_COVID_RISK' &&
        eventType !== 'MEASUREMENT_PAIN' &&
        eventType !== 'ACTIVITY_STEP_COUNT'
      )
    })

    Object.keys(this.patient.latestEvents).forEach(async eventType => {
      if (
        eventType !== 'RPM_NOTE' &&
        eventType !== 'MEASUREMENT_BODY_TEMPERATURE' &&
        eventType !== 'MEASUREMENT_COVID_RISK' &&
        eventType !== 'MEASUREMENT_PAIN' &&
        eventType !== 'ACTIVITY_STEP_COUNT'
      ) {
        await this.getEvent(eventType)
      }
    })
  },
  beforeDestroy() {
    this.clearPatientMetrics()
  },
}
</script>

<style lang="scss">
.mainChart {
  .yAxisLabel {
    background-color: green !important;
    border: 1px solid blue !important;
  }
}

.hoveredValue {
  background-color: #f5f5f5;
  border: 1px solid green !important;
}
.pointerClass {
  cursor: pointer;
}

.zoomClass {
  cursor: crosshair;
}
.arrow_box {
  background-color: green;
  padding: 6px;
}
.v-icon.active {
  transform: rotate(-180deg);
}
</style>
