<template>
  <v-card @click="$emit('toggle'), (visible = !visible)" rounded="lg" style="overflow: hidden" outlined>
    <v-row no-gutters>
      <v-card flat rounded="lg" class="px-3 pl-5 pt-2 pb-2 flex" style="overflow: hidden">
        <v-card
          flat
          style="position: absolute; top: 7%; left: 6px; border-radius:3px; height: 86%;"
          width="4"
          v-bind:color="visible ? color : 'grey lighten-2'"
        >
        </v-card>
        <v-row align="center" justify="space-between" class="pb-1 flex-nowrap" no-gutters>
          <span class="text-body-2 font-weight-medium text-uppercase text-truncate">
            <v-icon v-bind:color="visible ? color : 'grey lighten-2'" class="mr-1" size="16">{{ icon }}</v-icon>
            <span>{{ title }}</span>
          </span>

          <v-icon small v-if="visible">mdi-eye</v-icon>
          <v-icon small v-if="!visible">mdi-eye-off</v-icon>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row class="" no-gutters>
              <span class="text-h6 font-weight-medium"
                >{{ value }}<span class="text-body-2 font-weight-regular ml-1">{{ units }}</span></span
              >
            </v-row>
            <v-row class="" style="margin-top:-6px" no-gutters>
              <span class="text-caption font-weight-regular">{{ timestamp }}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'
import { mapState } from 'vuex'
import consts from '@/consts'

export default {
  data() {
    return {
      visible: true,
    }
  },
  props: {
    metricToDisplay: {
      type: Object,
      default: () => {
        return {}
      },
    },
    color: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    ...mapState('auth', ['appTheme', 'partner']),
    title() {
      if (consts.metricList[this.metricToDisplay.eventType].commonName) {
        return consts.metricList[this.metricToDisplay.eventType].commonName
      }
      return this.metricToDisplay.name
    },
    icon() {
      if (consts.metricList[this.metricToDisplay.eventType].icon) {
        return consts.metricList[this.metricToDisplay.eventType].icon
      }
      return 'mdi-water'
    },
    timestamp() {
      return this.relativeTime(this.metricToDisplay.timestamp)
    },
    value() {
      let innerVal = null

      let eventData = this.metricToDisplay.eventData

      switch (this.metricToDisplay.eventType) {
        case 'MEASUREMENT_BLOOD_PRESSURE':
          innerVal = eventData.systolicValue + '/' + eventData.diastolicValue
          break
        case 'MEASUREMENT_BODY_WEIGHT':
          innerVal = Math.round(eventData.value * 2.205 * 10) / 10
          break
        case 'MEASUREMENT_BLOOD_GLUCOSE':
          innerVal = Math.round(eventData.value * 100) / 100
          break
        case 'MEASUREMENT_BODY_TEMPERATURE':
          innerVal = Math.round(((eventData.value * (9 / 5) + 32) * 100) / 100)
          break
        case 'MEASUREMENT_PULSE':
          innerVal = Math.round(eventData.value)
          break
        case 'MEASUREMENT_ECG':
          if (eventData.afib) {
            innerVal = 'Possible Afib'
          } else {
            innerVal = 'No Afib'
          }
          break
        default:
          innerVal = eventData.value
          break
      }

      return innerVal
    },
    units() {
      if (consts.metricList[this.metricToDisplay.eventType].units) {
        return consts.metricList[this.metricToDisplay.eventType].units
      }
      return this.metricToDisplay.units
    },
  },
  methods: {
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return ''
    },
  },
}
</script>

<style></style>
